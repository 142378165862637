import restaurant from "./images/f5.jpg";
import img_card from "./images/1n.jpg";
import imgheaderrestaurant from "./images/f3.jpg";
import imgheader from "./images/f2.jpg";
import imgheaderm from "./images/f2.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/f.jpg";
import imgheadermiinimb from "./images/f.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Veaceslav",
        mireasa: "Adriana",
        data: "1 Septembrie 2023",
        data_confirmare: "20 august 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "ddoros82350@gmail.com", 
       tel: "+373 62162882",
       phone: "tel:+37362162882",
       viber: "viber://chat?number=%2B37362162882",
       whatsapp: "https://wa.me/+37362162882",
       messenger: "https://www.messenger.com/t/",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/iurii.denis",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Poiana Vadului",
            data: "1 septembrie 2023, vineri, ora 16:00",
            adress: "Strada Ștefan cel Mare 151, Vadul lui Vodă, R. Moldova",
            harta: "https://goo.gl/maps/N6wM7vHPpBun1WFo8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10863.067267155793!2d29.0629769!3d47.1036418!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c965357c298c05%3A0x3250d3517bc86d53!2sPoiana%20Vadului!5e0!3m2!1sro!2s!4v1684852254467!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
             
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea înseamnă un singur suflet care sălăsluiește în două corpuri.\" – Aristotel",
        }
    ],

}

export default data;